import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import style from "./swiperSlider.module.scss";
import { swiperOptions } from "./swiperOptions";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/free-mode";
import "./swiperCustomStyle.scss";

export const SwiperSlider = ({ story }) => {
  const nav = useNavigate();
  const { photo, category, _id, storyName } = story;
  const maxNumberOfSlides = 8;
  let slides = [];

  for (let i = 0; i < maxNumberOfSlides; i++) {
    slides.push(
      <SwiperSlide key={photo[i].publicUrl}>
        <div
          onClick={() => nav(`/${category.name}/${_id}`)}
          className={style.box}
        >
          <img
            className={style.image}
            alt={storyName}
            src={photo[i].publicUrl}
          />
        </div>
      </SwiperSlide>
    );
  }

  return (
    <div className={style.swiper__wrapper}>
      <Swiper {...swiperOptions}>{slides}</Swiper>
    </div>
  );
};
