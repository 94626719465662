import { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { CameraIcon, HomeIcon } from "components/UI/icons";
import Popup from "components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import { SubMenu } from "./SubMenu/SubMenu";
import clsx from "clsx";
import useDetectScroll from "@smakss/react-scroll-direction";
import style from "./bottomMenu.module.scss";
import { HOME } from "utils/RouterConstants/routerConstants";

export const BottomMenu = () => {
  const { scrollDir, scrollPosition } = useDetectScroll({
    thr: 1,
    scrollDown: false,
    scrollUp: true,
    still: false,
  });
  const nav = useNavigate();
  const [value, setValue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  return (
    <div
      className={clsx(style.btMenu, {
        [style.btMenu_show]: !scrollDir && scrollPosition.top > 50,
      })}
    >
      <Popup
        open={showModal}
        onClose={closeModal}
        content={<SubMenu closeModal={closeModal} />}
      />

      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={openModal}
          className={style.btMenu_item}
          label="Portfolio"
          icon={<CameraIcon />}
        />
        <BottomNavigationAction
          className={style.btMenu_item}
          label="Home"
          icon={<HomeIcon />}
          onClick={() => nav(HOME)}
        />
      </BottomNavigation>
    </div>
  );
};
