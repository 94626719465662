import { Stack, Typography, Divider } from "@mui/material";
import style from "./storyCard.module.scss";
import { SwiperSlider } from "./Swiper/SwiperSlider";
export const StoryCard = ({ story }) => {
  const { location, date, storyName } = story;

  return (
    <section
      className={style.story__card}
      data-aos="zoom-in"
      data-aos-duration="500"
    >
      <Stack
        alignItems={"center"}
        sx={{
          marginBottom: { lg: 6, md: 5, xs: 2 },
          marginTop: { lg: 6, md: 5, xs: 3 },
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "14px" },
            textTransform: "uppercase",
          }}
        >
          {storyName}
        </Typography>

        <Typography
          sx={{
            fontSize: { lg: "14px", md: "12px", xs: "11px", color: "grey" },
          }}
        >
          {`${location},  ${date}`}
        </Typography>
      </Stack>

      <SwiperSlider story={story} />
      <Divider
        sx={{ width: "80%", marginTop: { lg: 4, md: 3, sm: 2, xs: 1 } }}
      />
    </section>
  );
};
